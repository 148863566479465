import React from "react";
import moment from "moment";
import Avatar from "./Avatar";

class ScoreBoard extends React.Component {
  render() {
    return (
      <>
        <div className="scoreboard-wrapper">
          <div className="scores-wrapper">
            <div className="text-left">
              <div className="score">
                <label className="mr-2 mb-0">Score</label>
                {this.props.gamePlaces &&
                  this.props.gameSession &&
                  this.props.gameSession.submissions &&
                  this.props.gameSession.submissions.length}
                /{this.props.gamePlaces && this.props.gamePlaces.length}
              </div>
            </div>
            <div className="text-right">
              <div className="score">
                <label className="mr-2 mb-0">Timer</label>

                {this.props.tick.current > 0 && (
                  <>
                    {(
                      "00" +
                      moment.duration(this.props.tick.current, "seconds").minutes()
                    ).slice(-2)}
                    :
                    {(
                      "00" +
                      moment.duration(this.props.tick.current, "seconds").seconds()
                    ).slice(-2)}
                  </>
                )}

                {this.props.tick.current <= 0 && <>...</>}
              </div>
            </div>
          </div>

          {this.props.isMultiplayer &&
            this.props.gameSession &&
            this.props.gameSession.players && (
              <div className="players-bar">
                {this.props.gameSession.players.map((player) => {
                  return (
                    <div key={player.uid} className={this.props.playerUID==player.uid ? 'avatar-me' : 'avatar-other'}><Avatar {...player} showScore={true} /></div>
                  );
                })}
              </div>
            )}
        </div>
        <style jsx="true">{`
          .players-bar .avatar {
            width: 50px;
            height: auto;
            outline: 1px solid #00000021;
          }

          .players-bar .avatar img {
            max-width: 40px;
            height: 40px;
          }

          .scoreboard-wrapper {
            position: fixed;
            top: 10px;
            left: 0;
            right: 0;
            width: 95%;
            z-index: 9999;
            margin: auto;

            width: 800px;
            max-width: 98%;
          }

          .scores-wrapper {
            background: #fff;
            display: flex;
            padding: 5px 10px;
            border-radius: 20px;
            flex-direction: row;
            justify-content: space-between;

            opacity: 0.9;
          }

          .players-bar {
            margin-top: 5px;
            display: flex;
          }

          :global(.players-bar .avatar) {
            opacity: 0.9;
            outline: 3px solid #ff005b;
          }

          :global(.players-bar .avatar-me) {
            outline: 3px solid #ff005b;
          }
          

          .score {
            font-size: 1em;
            font-weight: 700;
          }

          label {
            font-size: 1em;
            padding: 0;
          }
        `}</style>
      </>
    );
  }
}

export default ScoreBoard;
