import React from "react";
import { Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import Lottie from "react-lottie";
import * as animationData from "./../assets/lf20_Y08urr.json";
import Slide from "react-reveal/Slide";

class MenuStart extends React.Component {
  render() {
    return (
      <div className="start-menu menu">
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: animationData.default,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid meet",
            },
          }}
          height={200}
          width={300}
          segments={[34, 152]}
        />

        <Slide bottom>
          <div style={{ minHeight: "100px", width: "100%" }}>
            <img
              src="img/logoNtxt.svg?v"
              className="img-fluid logo-title"
              alt="logo"
            />
            <h3 className="text-white subtitle d-none">
              Challenge your Geography skills
            </h3>
          </div>

          <div className="mx-5">
            <Button
              variant="success"
              className="w-100 my-2 shine"
              onClick={this.props.onStartSinglePlayer}
            >
              Start Game
            </Button>
            <Button
              variant="primary"
              className="w-100 my-2 shine"
              onClick={this.props.onStartMultiPlayer}
            >
              Play with friends
            </Button>
          </div>

        </Slide>


        <style jsx="true">{`
          .start-menu {
            background: #20c7ff;

            display: flex;
            flex-direction: column;
            justify-content: space-around;

            align-items: center;

            border-radius: 30px;

            width: 400px;
            margin: 0 auto;
          }
          .subtitle {
            text-shadow: #373737 0px 0px 5px;
            font-size: 1.2em;
            margin: 1em 0;
          }
          .logo-geo {
            max-width: 300px;
          }
          .logo-title {
            max-width: 300px;
            margin: 2em auto;
          }
        `}</style>
      </div>
    );
  }
}

export default withTranslation()(MenuStart);
