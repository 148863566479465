import React from "react";

class SelectGameMode extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isGameModeChallenge: true };
  }

  handleChange = () => {
    this.props.onGameModeSelected(
      !this.state.isGameModeChallenge ? "challenge" : "default"
    );
    this.setState({
      isGameModeChallenge: !this.state.isGameModeChallenge,
    });
  }

  render() {
    return (
      <>
        <label className="gameMode-select">
          <div
            className={
              this.state.isGameModeChallenge
                ? "toggle toggle-compete"
                : "toggle toggle-casual"
            }
            onClick={this.handleChange}
          >
            
            <div className="title-toggle toggle-indicator"></div>

            
            <div className="title-toggle title-toggle-casual">
              <img src={"img/turtle.svg"} height={40} />
            </div>
            <div className="title-toggle title-toggle-compete">
              <img src={"img/cheeta.svg"} height={40} />
            </div>

           
          </div>
        </label>

        <style jsx="true">{`
          .gameMode-select {
            background: #3918c4;
            border-radius: 40px;
            padding: 5px 0;
          }

          .toggle {
            overflow: hidden;
            position: relative;
            height: 65px;
            width: 300px;
            cursor: pointer;
          }

          .title-toggle {
            width: 50%;
            position: absolute;
            top: 10px;
            left: 5px;

            transition: 0.4s;
            text-align: center;

            z-index: 80;
            
          }
  
          .title-toggle-compete {
            right: 10px;
            left: auto;
          }

          .toggle-indicator {
            background: #fff;
            border-radius: 40px;
            box-shadow: 6px 6px 0px black;
            height: 55px;
            top:3px;
            width: 45%;
          }
          .toggle-casual .toggle-indicator {
            left: 10px;
          }
          .toggle-compete .toggle-indicator{
            left: 150px;
          }
          
        `}</style>
      </>
    );
  }
}

export default SelectGameMode;
