
import React from 'react'

export default function BigTitleOverlay(props) {

  const [counter, setCounter] = React.useState(5);
  let timerInterval;

  const count = () => {
    if (counter <= 0) return;
    setCounter(counter - 1);
  }

  React.useEffect(() => {
    timerInterval = setInterval(count, 1000)
    return () => {
      clearInterval(timerInterval)
    }
  }, [counter])

  React.useEffect(() => {
    setCounter(5);
  }, [props.title])


  return (
    <>
    <div className="big-title-overlay" >  
      <div className={"big-title  " + (props.title.length > 10 ? 'long-title' : '')} style={{color: props.color}}>{props.title}</div>
      <div className={"dialog-in-game " + (props.title.length > 10 ? 'long-title' : '')}>
        Next country in {counter}...
      </div>
    </div>
 
      <style jsx="true">{`

        .big-title-overlay {
          position: fixed;
          background: #000;
          top: 0;
          margin: 10vh auto;
          width: 100%;
          z-index: 9999; 
        }

        .big-title {
          font-weight: 800;
          -webkit-text-stroke-width: 4px;
          -webkit-text-stroke-color: black;
          line-height: 100%;

          font-size: 10em;
        }

        .big-title.long-title {
          font-size: 7em;
        }


        @media only screen and (min-width: 600px) {
          .big-title-overlay {
            
            background: #542feb5e;
            height: 100vh;
            top: 0;
            bottom: 0;
            margin: 0 auto;
            padding-top: 150px;

          }

          .dialog-in-game {
            position: fixed;
            
            color: #000;
            bottom: 50px;
            margin: 10vh auto;
            width: 100%;
            z-index: 9999;
            font-weight: 800;
            
            padding: .5em 0 1.2em 0;
  
            background: url('img/bubble_countdown.svg') no-repeat center center;
            background-size: contain;

            font-size: 2.5em;
          }

        }

        @media only screen and (max-width: 600px) {
          .big-title-overlay {
            background: url('img/bubble_countdown_mobile.svg') no-repeat center center; 
            background-size: contain;
            padding: 3rem;
            top: auto;
            bottom: 130px;
            margin: 0 auto;
            opacity: .9;

            
          }

          .big-title {

            -webkit-text-stroke-width: 2px;
            font-size: 3em;
          }

          .big-title.long-title {
            font-size: 2em;
          }

          .dialog-in-game {
            color: #542feb;
            font-weight: 800;
            font-size: 1.5em;
          }
        }

        


      
/*
style={{fontSize:  ? '1.5em' : '2em'}}
*/

      `}</style>
    </>
    
    
  )

}
