import React from "react";
import { Button } from "react-bootstrap";

import Lottie from "react-lottie";
import * as movingEye from "./../assets/moving_eye.json";
import { tileLayer } from "leaflet";

const DEFAULT_AVATAR = "anonymous";
const AVATARS = [
  "bee",
  "butterfly",
  "camel",
  "crab",
  "dog",
  "fish",
  "flamingo",
  "rat",
  "shark",
  "frog",
  "giraf",
  "jelly",
];

const AVATARS_EYE_POS = {
  bee: { x1: 21, x2: 35, y1: 8, y2: 8, height: 20, width: 15 },
  butterfly: { x1: 34, x2: 15, y1: 25, y2: 25, height: 30, width: 22 },
  camel: { x1: 48, y1: 1, height: 18, width: 10 },
  crab: { x1: 16, x2: 33, y1: 30, y2: 30, height: 25, width: 20 },
  dog: { x1: 44, y1: 4, height: 19, width: 11 },

  fish: { x1: 46, y1: 20, height: 20, width: 15 },
  flamingo: { x1: 32, y1: 2, height: 18, width: 10 },

  rat: { x1: 43, y1: 4, height: 20, width: 13 },

  shark: { x1: 35, x2: 17, y1: 22, y2: 22, height: 21, width: 17 },
  frog: { x1: 41, y1: 9, height: 22, width: 14 },
  giraf: { x1: 15, y1: 8, height: 22, width: 14 },
  jelly: { x1: 29, x2: 15, y1: 11, y2: 11, height: 20, width: 12 },
};

const PLAYER_COLORS = [
  "#35E95A",
  "#FFB403",
  "#61F5F9",
  "#F896FF",
  "#17BDC1",
  "#FFE60D",
  "#E8794D",
  "#FF005B",
  "#66C5FF",
  "#F3C3B0",
  "#9483ED",
  "#FF99BD",
];

class Avatar extends React.Component {
  static getAvatars() {
    return AVATARS;
  }

  getAvatar = () => {
    if (this.props.id) {
      return AVATARS[this.props.id % AVATARS.length];
    }
    if (this.props.avatar) {
      return this.props.avatar;
    }
    return DEFAULT_AVATAR;
  };

  // @TODO: xss?
  getStyle = () => {
    return {
      opacity: this.props.opacity || 1,
      background: this.props.color || "#F6F9E4",
      //borderColor: PLAYER_COLORS[AVATARS.indexOf(this.props.avatar)]
    };
  };

  onAvatarSelected = () => {
    if (this.props.onAvatarSelected) {
      this.props.onAvatarSelected(this.getAvatar());
    }
  };

  render() {
    let avatarId = this.getAvatar();

    return (
      <>
        <div
          key={"avatar-" + avatarId + "-" + this.props.score}
          className={
            "avatar avatar-" +
            avatarId +
            " " +
            (this.props.className || "") +
            (this.props.score > 0 ? " animate__animated animate__tada" : "")
          }
          style={this.getStyle()}
          onClick={this.onAvatarSelected}
        >
          <img src={"img/avatars2/" + avatarId + ".svg"} />

          {this.props.animateEye && (
            <>
              <div
                className="animation-eye animation-eye-left"
                style={{
                  position: "absolute",
                  left: AVATARS_EYE_POS[avatarId].x1,
                  top: AVATARS_EYE_POS[avatarId].y1,
                }}
              >
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: movingEye.default,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid meet",
                    },
                  }}
                  height={AVATARS_EYE_POS[avatarId].height}
                  width={AVATARS_EYE_POS[avatarId].width}
                />
              </div>

              {AVATARS_EYE_POS[avatarId].x2 && (
                <div
                  className="animation-eye animation-eye-right"
                  style={{
                    position: "absolute",
                    left: AVATARS_EYE_POS[avatarId].x2,
                    top: AVATARS_EYE_POS[avatarId].y2,
                  }}
                >
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: movingEye.default,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid meet",
                      },
                    }}
                    height={AVATARS_EYE_POS[avatarId].height}
                    width={AVATARS_EYE_POS[avatarId].width}
                  />
                </div>
              )}
            </>
          )}

          {this.props.showScore && (
            <div className="avatar-score">{this.props.score}</div>
          )}

          <style jsx="true">{`
            .avatar {
              width: 70px;
              height: 70px;
              margin: 4px;

              position: relative;
              display: flex;
              justify-content: center;
              flex-wrap: wrap;

              background: #f6f9e4;
              border: 0px solid #f6f9e4;
              
              text-align: center;

              user-select: none;
            }

            .avatar img {
              max-width: 60px;
              padding: 4px;
            }

            .avatar-score {
              display: block;
              
              height: 1.5em;
              line-height: 1.5em;
              background: black;
              color: white;
              width: 100%;
              opacity: 0.7;
              font-size: 0.7em;
            }
          `}</style>
        </div>
      </>
    );
  }
}

export default Avatar;
