import React from "react"; 
class Credit extends React.Component {
  render() {
    return (
      <>
        <div className="credit-wrapper" onClick={this.props.onCreditClick}>
          <span className="py-1 px-3">About this project</span>
        </div>

        
  
        <div className="mt-5" >
           
          <a style={{ color: '#fff', textShadow: '0px 1px 1px black' }} href="https://www.instagram.com/thegeoquiz/" target="_blank" ><img src='/img/instagram.svg' style={{ verticalAlign: 'middle' }} height={30} title="The GeoQuiz instagram channel" /></a>
          
          <a className="ml-3" style={{ color: '#fff', textShadow: '0px 1px 1px black' }} href="https://discord.gg/w9cEqTnPaa" target="_blank" ><img src='/img/discord.svg' style={{ verticalAlign: 'middle' }} height={33} title="Join our Discord Channel" /></a> 
         
</div>
        
        <style jsx="true">{`
          .credit-wrapper {
            cursor: pointer;
            padding: 20px 0;
          }

          .credit-wrapper span {
            background: rgba(0, 0, 0, 0.9);
            color: #fff;
            border-radius: 20px;
          }
        `}</style>
      </>
    );
  }
}

export default Credit;
