import React from "react";
import { Button } from "react-bootstrap";

import Lottie from "react-lottie";
import * as animHappy from "../assets/smile.json";
import * as animSad from "../assets/sad_face.json";
import * as animTired from "../assets/tired.json";
import * as anim1 from "../assets/one_eye.json";
import * as anim2 from "../assets/OMG.json";

const SCORE_CAT = [0, 30, 50, 70, 100];

const PHRASES = {
  0: "You have to start somewhere",
  30: "Keep it up!",
  50: "That's more than half!",
  70: "That was incredible!",
  100: "WOW! That was amazing!",
};

const SCORE_ANIM = {
  0: animTired,
  30: animSad,
  50: animHappy,
  70: animHappy,
  100: animHappy,
};

class MenuGameEnd extends React.Component {

  getGameScore = () => {
    let placesFound = this.props.gameSession.submissions.filter(
      (s) => s.uid != "bot"
    ).length;
    let placesFoundPercentage =
      Math.floor((100 * 100 * placesFound) / this.props.gamePlaces.length) /
      100;

    return placesFoundPercentage;
  };

  getGameScoreCategory = () => {
    let gameScore = this.getGameScore();
    for (var i in SCORE_CAT) {
      if (gameScore <= SCORE_CAT[i]) {
        return SCORE_CAT[i];
      }
    }
  }

  copyToClipboard = (e) => {
    this.textArea.type = "text";
    this.textArea.select();
    document.execCommand("copy");
    this.textArea.type = "hidden";
    e.target.focus();
  };

  render() {
    let shareLink = window.location.origin;
    let shareText =
      "I found " +
      this.getGameScore() +
      "%! TheGeoQuiz - Challenge your geography skills: " +
      shareLink;
    
    let isMinimized = this.state && this.state.isMinimized;

    return (
      <div className={"menu-gameend-wrapper" + (isMinimized ? ' is-minimized' : '')}>
        <div className={"menu-gameend menu text-white mt-2 px-1"}>
          <div className={"geo geo-" + this.getGameScoreCategory()}>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: SCORE_ANIM[this.getGameScoreCategory()].default,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid meet",
                },
              }}
              height={100}
              width={100}
            />
          </div>

          <div className="btn-close btn-min" onClick={()=>{this.setState({isMinimized: !isMinimized})}}></div>

          {!isMinimized && <>
            <h2>You found {this.getGameScore()}% of the countries</h2>
           <h2 className="font-weight-bold">{PHRASES[this.getGameScoreCategory()]}</h2>
          </>}
          
          <div className="mx-5">
            <Button
              variant="success"
              className="w-100 my-4"
              onClick={this.props.restartGame}
            >
              Play Again!
            </Button>

            {!isMinimized && <>
            <h3 className="tilt">Share your score</h3>
            <div className="d-flex btns-share">
              <input
                ref={(textarea) => (this.textArea = textarea)}
                type="hidden"
                defaultValue={shareText}
                readOnly={true}
              />
              <a href="#" onClick={this.copyToClipboard}>
                <img src="img/btn-copy.png" />
              </a>

              <a
                href={
                  "https://api.whatsapp.com/send?text=" +
                  encodeURIComponent(shareText)
                }
                target="_blank"
              >
                <img src="img/btn-wa.png" />
              </a>
              <a
                href={
                  "https://www.facebook.com/dialog/share?app_id=699693493564382&redirect_uri=" +
                  shareLink +
                  "&href=" +
                  encodeURIComponent(shareLink)
                }
              >
                <img src="img/btn-fb.png" />
              </a>
            </div>
            </>}
          </div>

          <style jsx>{`
            .tilt {
              transform: rotateZ(-1deg);
            }
            .btns-share::before {
              content: " ";
              display: block;
              background: url("img/arrow-down.png") no-repeat;

              width: 60px;
              height: 70px;
              position: absolute;
              z-index: 999;
              background-size: contain;
              right: -20px;
              top: -20px;
            }
            .btns-share {
              justify-content: center;
              position: relative;
            }
            .btns-share img {
              width: 35px;
              margin: 1em 0.5em;
            }

            .menu-gameend {
              background: #542feb;
              border-radius: 30px;
              width: 350px;
              margin: 0 auto;

              padding: 65px 0 50px 0;

              position: relative;
              top: 90px;
            }



            .is-minimized .menu-gameend .btn-close.btn-min {
              transform: rotateX(180deg);
            }

            .is-minimized .menu-gameend {
              padding: 20px 0 0 0;
            }

            .geo {
              top: -40px;
              left: -15px;
            }

            .geo-0 {
              top: -60px;
              left: -35px;
            }

            .geo-30 {
              top: -40px;
              left: -15px;
            }
            
            .menu-gameend-wrapper {
              background: rgba(0, 0, 0, 0.7);
              height: 100vh;

              position: fixed;
              top: 0;
              width: 100vw;
              z-index: 9999;
            }
            
            .menu-gameend-wrapper.is-minimized {
              background: transparent;
              height: auto;
              width: auto;
              position: fixed;
              top: 0;
              left: 0;
              right: 0;
              z-index: 9999;
            }

            .btn {
              border-radius: 20px;
              font-weight: 600;
            }


            .btn-close.btn-min {
              border: 0;
              right: -10px;
              top: -10px;
              background: #542feb url("img/arrow.svg") no-repeat center center;
            }
          `}</style>
        </div>
      </div>
    );
  }
}

export default MenuGameEnd;
