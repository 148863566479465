import React from "react";
import { Button } from "react-bootstrap";
import Lottie from "react-lottie";
import * as animationData from "./../assets/OMG.json";

class DialogAreYouSure extends React.Component {
  render() {
    return (
      <>
        <div className="areyousure-menu-wrapper p-4 text-white">
          <div className="areyousure-menu py-4 px-2">
            <div className="geo-face-sad d-none">
              <img src="img/faces/shock.svg" className="img-fluid" />
            </div>
            <div className="geo-face-shock">
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: animationData.default,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid meet",
                  },
                }}
                height={100}
                width={100}
              />
            </div>

            <h1 className="px-5 py-3">
              <img src="img/rusure.svg" alt="Are you sure?" />
            </h1>

            <div className="my-4">
              <Button
                variant="success"
                className="px-2 mx-1"
                onClick={this.props.onNo}
              >
                Go Back
              </Button>
              <Button
                variant="secondary"
                className="px-2 mx-1"
                onClick={this.props.onYes}
              >
                Quit game
              </Button>
            </div>
          </div>
        </div>
        <style jsx="true">{`
          .geo-face-sad {
            width: 75px;
            position: absolute;
            top: -20px;
            left: 0;
            right: 0;
            margin: auto;
          }

          .geo-face-shock {
            position: absolute;
            top: -35px;
            left: 0;
            right: 0;
            padding-right: 8px;
            margin: auto;
          }

          .areyousure-menu {
            background: #542feb;
            color: #fff;
            border-radius: 20px;

            position: relative;

            max-width: 450px;
            margin: 150px auto 0 auto;

            font-size: 0.8em;
          }
          .areyousure-menu-wrapper {
            background: rgba(0, 0, 0, 0.8);
            height: 100vh;

            position: fixed;
            top: 0;
            width: 100vw;
            z-index: 9999;
          }
        `}</style>
      </>
    );
  }
}

export default DialogAreYouSure;
