import _ from "lodash";

export default class utils {
  static indexPlaceName = (name) => {
    return name
      .toLowerCase()
      .replace(".", "")
      .replace("the ", "")
      .replace("saint", "st")
      .replace("am main", "")
      .replace("im breisgau", "")
      .replace("am rhein", "")
      .replace(/[/"/'-\s׳*]/g, "")
      .replace(/ט/g, "ת")
      .replace(/יי/g, "י")
      .replace(/וו/g, "ו")
      .replace(/\(.+\)/, "")
      .trim();
  };

  static openFullscreen = () => {
    var elem = document.documentElement;

    try {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen();
      }
    } catch (err) {
      console.log("fullscreen rejected", err);
    }
  };

  static getShuffledPlaces = (placesArr) => {
    // groups of 10 by difficulty score
    //let placesArr = Object.keys(places.names).map(code=>{return {id: code, score: places.names[code].score}});
    placesArr.sort((a, b) => {
      return b.score - a.score;
    });
    let placesSortedByScore = _.flatten(
      _.chunk(placesArr, 10).map(_.shuffle)
    ).map((p) => p.id);

    return placesSortedByScore;
  };
}
