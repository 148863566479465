import React from "react";
import VoiceService from "../middleware/VoiceService";

class VoiceControl extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userInput: "",
    };

    if (
      typeof window.SpeechRecognition != "undefined" ||
      typeof window.webkitSpeechRecognition != "undefined"
    ) {
      this.voice = new VoiceService(this.props);
    }
  }

  onInputChange = (e) => {
    let isFound = this.props.onPlaceInput(e.target.value);
    this.setState({
      userInput: isFound ? "" : e.target.value,
      isRecording: this.voice.isRecording,
    });
  };

  onMicClick = () => {
    if (this.voice) {
      this.voice.onMicClick();

      this.setState({ isRecording: this.voice.isRecording });
    } else {
      alert(
        "Your browser does not support the Voice options. You can try using Chrome on Android/Windows instead to use this feature."
      );
    }
  };

  render() {
    return (
      <>
        <div
          className={
            "voice-control-wrapper " +
            (this.state.isRecording ? "active" : "") +
            (this.voice ? " voice-enabled" : " voice-disabled")
          }
          onClick={this.onMicClick}
        >
          <img src="img/microphone.png" />

          <style jsx="true">{`
            .voice-disabled img {
              opacity: 0.2;
            }

            .voice-control-wrapper.active {
              background: red;
            }
            .voice-control-wrapper img {
              height: 30px;
              margin: 5px;
            }
            .voice-control-wrapper {
              position: absolute;
              right: 0px;
              top: 0px;
              height: 40px;
              cursor: pointer;
            }
          `}</style>
        </div>
      </>
    );
  }
}

export default VoiceControl;
