import openSocket from "socket.io-client";

// session should be in state?

class MultiplayerService {
  constructor(events) {
    this.getSession = events.getSession;
    this.gameOnPlaceFound = events.onPlaceFound;
    this.onSessionUpdate = events.onSessionUpdate;
  }

  connect(options) {
    // @TODO: move to config/env
    let endpoint = "https://thegeoquiz-server-v2.herokuapp.com/";
    if (window.location.hostname == "localhost") {
      endpoint = "http://localhost:3022";
    }
    this.socket = openSocket(endpoint);

    //var socket = io();

    this.socket.on("connect", (response) => {
      console.log("app is connected", response);
      // let app know
      console.log("the options", options);
      if (options.createGame) {
        this.requestCreateGame();
      }
      if (options.joinGame) {
        this.requestJoinGame({
          gid: options.gid,
          uid: options.uid,
          avatar: options.avatar,
        });
      }
    });

    this.socket.on("game state update", this.onGameStateUpdate);
    this.socket.on("place found", this.onPlaceFound);
  }

  disconnect() {
    this.socket.disconnect();
  }

  onGameStateUpdate = (sessionUpdate) => {
    console.log(
      "game state update:",
      sessionUpdate.currentPlacePos,
      "curr:",
      this.getSession().currentPlacePos
    );
    if (sessionUpdate.hash != this.getSessionHash()) {
      this.onSessionUpdate(sessionUpdate);
    }
  };

  onPlaceFound = (submission) => {
    let currSession = this.getSession();
    currSession.submissions.push(submission);
    currSession.submissions.sort(function (a, b) {
      return a.place_code.localeCompare(b.place_code);
    });
    this.onSessionUpdate(currSession);

    this.gameOnPlaceFound(submission);
  };

  requestJoinGame = (params) => {
    this.socket.emit("join game", params, (response) => {
      if (response.playerUID) {
        this.playerUID = response.playerUID;
      }
      if (response.error) {
        let currSession = this.getSession();
        currSession.gameError = response.error;
        this.onSessionUpdate(currSession);
      }
    });
  };

  requestCreateGame = (options) => {
    console.log("creating game");
    this.socket.emit("create game", { game_mode: "challenge" }, (uid) => {
      this.playerUID = uid;
    });
  };

  requestPlaceFound = (submission) => {
    let request = {
      uid: this.playerUID,
      hash: this.getSessionHash(),
      gid: this.getSession().gid,
      place_code: submission.place_code,
    };

    this.socket.emit("place found", request);
  };
  requestUpdatePlayer = (player) => {
    console.log(this.getSession(), this.playerUID);
    let currSession = this.getSession();
    currSession.players.find((p) => p.uid == this.playerUID).avatar =
      player.avatar;
    console.log("player update:", currSession);
    this.onSessionUpdate(currSession);

    let request = player;
    request.uid = this.playerUID;
    request.gid = this.getSession().gid;
    this.socket.emit("update player", request);
  };
  requestStartGame = (params) => {
    params.gid = this.getSession().gid;
    this.socket.emit("start game", params);
  };

  getSessionHash() {
    var str = JSON.stringify(this.getSession());
    var hash = 0;
    if (str.length == 0) {
      return hash;
    }
    for (var i = 0; i < str.length; i++) {
      var char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
  }
}

export default MultiplayerService;
