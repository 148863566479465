
import React from 'react'  

export default function RecentAnswersOverlay(props) {
 
  // @TODO: rolling effect
  
  const list = props.submissions.sort((s1,s2)=>s1.submissionTime-s2.submissionTime).filter((s,i)=>i>=props.submissions.length-3).map(submission => ({
    color: props.players?.find(p=>p.uid == submission.uid)?.color || '#fff',
    name: props.gamePlaces.find(gp=>gp.id == submission.place_code).eng
  }));
  
  const getOpacity = (i) => {
    if (props.submissions.length < 2) return 1;
    if (i == 2) return 1;
    if (i == 1) return 0.5;
    if (i == 0) return 0.3;

    return 1;
  } 
   
  return (
    <>
      <div className="recent-answers-overlay" > 
        <ul>
          {list.map((item, i) => (
            <li key={item.name} style={{opacity: getOpacity(i), color:item.color }}>{item.name}</li>
          ))}
        </ul>
    </div>
 
      <style jsx="true">{`

        .recent-answers-overlay {
          position: fixed;
          top: 20px;
          left: 0;
          z-index: 9999;
          font-size: 1em; 
        }
  
        .recent-answers-overlay ul {
          list-style: none;
          text-align: left;
        }

        .recent-answers-overlay li {
          font-weight: bold;
          color: #fff;
          text-shadow: 1px 1px 1px black;
        }

        @media only screen and (min-width: 600px) {
          
        }

        @media only screen and (max-width: 600px) {
          .recent-answers-overlay {
            display: none;
          }
        }

         
      `}</style>
    </>
    
    
  )

}
