import React from "react";
import { Button } from "react-bootstrap";
import SelectGameMode from "./SelectGameMode";

class DialogInstructions extends React.Component {
  constructor(props) {
    super(props);

    this.state = { selectedGameMode: "challenge" };
  }

  isVoiceSupport = () => {
    return !(
      typeof window.SpeechRecognition == "undefined" &&
      typeof window.webkitSpeechRecognition == "undefined"
    );
  };

  onClickGameStart = () => {
    console.log("dismissed called");
    this.props.onDismiss();
    if (this.props.isMultiplayer) return;

    this.props.startGame({
      isMultiplayer: false,
      game_mode: this.state.selectedGameMode,
      userInitiated: false,
    });
  };

  render() {
    return (
      <>
        <div className="instructions-menu-wrapper p-4 text-white">
          <div className="instructions-menu py-4 px-2">
            <h1 className="px-3 py-3">
              <img src="img/howtoplay.svg" alt="How to Play" />
            </h1>

            {this.props.isMultiplayer &&
              this.state.selectedGameMode == "default" && (
                <p>
                  Name as many countries as you can before your time runs out.
                  How many can you find before the time runs out?
                </p>
              )}

            {!this.props.isMultiplayer &&
              this.state.selectedGameMode == "default" && (
                <p>
                  Name as many countries as you can before your time runs out.
                  Type the country name and it will show on the map.
                </p>
              )}

            {this.props.isMultiplayer &&
              this.state.selectedGameMode == "challenge" && (
                <p>
                  Name the country shown on the map before your time runs out.
                  The first to name the country before the time runs out wins
                  more points!
                </p>
              )}

            {!this.props.isMultiplayer &&
              this.state.selectedGameMode == "challenge" && (
                <p>
                  Name the country shown on the map before your time runs out.
                </p>
              )}

            {!this.props.isMultiplayer && (
              <SelectGameMode
                onGameModeSelected={(selectedGameMode) => {
                  this.setState({ selectedGameMode: selectedGameMode });
                }}
              />
            )}

            {this.isVoiceSupport() && (
              <p>
                <img
                  src="img/microphone.png"
                  style={{ filter: "invert(1)" }}
                  height="25px"
                />{" "}
                Press the microphone and shout the country’s name instead of
                typing.
              </p>
            )}

            <div className="my-4">
              <Button
                variant="success"
                className="px-4 btn-start"
                onClick={this.onClickGameStart}
              >
                Lets Start!
              </Button>
            </div>
          </div>
        </div>
        <style jsx="true">{`
          .btn-start {
            border-radius: 20px;
          }
          .instructions-menu-wrapper {
            position: absolute;
            bottom: 130px;
            z-index: 9999;
            width: 100%;
          }

          .instructions-menu {
            background: #542fea;

            border-radius: 25px;
            background-size: contain;
            color: #fff;

            position: relative;
            width: 320px;
            max-width: 90%;
            margin: 0 auto;
            border: 1px solid black;
          }

          .instructions-menu::after {
            background: url(img/bubble_bottom.svg) no-repeat;
            background-position: 0px -1px;
            position: absolute;
            bottom: -85px;
            left: -1px;
            width: 320px;
            height: 115px;
            content: " ";
            max-width: 101%;
            content: " ";
            background-size: contain;
          }
        `}</style>
      </>
    );
  }
}

export default DialogInstructions;
