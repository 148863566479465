import React from 'react'
import VoiceControl from './VoiceControl';

import Lottie from 'react-lottie';
import * as animationData from './../assets/lf20_pGfprR.json'

class UserInputBar extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      userInput: "",
      placeholder: "Enter country...",
      isAnimationPaused: true
    }
  }
  
  componentDidMount() {
    // Animate eyes for 15 seconds every 60 seconds
    setInterval(() => {
      //console.log("start animating");
      this.setState({isAnimationPaused: false});
      setTimeout(()=>{
        //console.log("stop animating");
        this.setState({isAnimationPaused: true});
      }, 1000*10);
    }, 1000*35)
  }

  onInputChange = (e) => {
    let isFound = this.props.onPlaceInput(e.target.value);
    this.setState({userInput: isFound ? "" : e.target.value})
  }

  onVoiceTranscript = (transcript) => {
    this.setState({placeholder: transcript});
    
    if (this.resetPlaceholderTimer) {
      clearInterval(this.resetPlaceholderTimer);
    }

    this.resetPlaceholderTimer = setTimeout(() => {
      this.setState({placeholder: "Enter country..."});
    },3000);
  }

  render() {
    return <>
      <div className="userinputbar-wrapper">

        <div className="geo-face d-none"><img src='img/faces/happy.svg' /></div>

        <div className="geo-face">
          <Lottie options={{loop: true,
            autoplay: true, 
            animationData: animationData.default,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid meet'
            }}}
            height={50}
            width={80}

            isPaused={this.state.isAnimationPaused}
          />
        </div>

        <div className="btn-close" onClick={this.props.onEndGame}></div>

        <div className="mx-3 mb-3">
          <div className="inputs-wrapper">
            <input type="text" className="form-control" value={this.state.userInput} placeholder={this.state.placeholder}  onChange={this.onInputChange} />
            <VoiceControl {...this.props} onTranscript={this.onVoiceTranscript} />
          </div>
        </div>
      </div>
      <style jsx="true">{`
        .inputs-wrapper {
          position: relative;
        }

        .geo-face {
          transform: translateY(-25px);
          margin: 0 auto;
        }

        .userinputbar-wrapper .geo {
          top: -40px;
        }

        .form-control {
          height: 40px;
        }

        .userinputbar-wrapper {
          background: #542feb;

          position: fixed;
          bottom: 0;
          left:0;
          right: 0;
          z-index: 9999;

          margin: 0 auto;
          width: 800px;
          max-width: 100%;

          border-top-right-radius: 50%;
          border-top-left-radius: 50%;
        }
      `}</style>
    </>
  }
}

export default UserInputBar;
