import React from 'react'
import { Map, Marker, Tooltip, Circle, GeoJSON, ImageOverlay } from 'react-leaflet'

const colorScheme = {
  failure: {primary: "red", dark: "#b91400", light: "#ff844c"},
  found: {primary: "#35E95A", dark: "#542FEB", light: "#66ffa6"},
  notfound: {primary: "#F6F9E4", dark: "#542FEB", light: "#ffffff"},
  hover: {primary: "#35E95A", dark: "#542FEB", light: "#ffffff"},
  other: {primary: "#ffffff", dark: "#ffffff", light: "#ffffff"},
  disabled: {primary: "#fafafa", dark: "#fafafa", light: "#fafafa"}
};

class GameMap extends React.Component {
  constructor(props) {
    super(props)
  }

  onViewportChanged = (viewport) => {
    this.setState({ viewport })
  }

  isCurrentlyFocused = (place) => {
    return !this.props.hideCurrentlyFocused && this.props.gameSession.game_mode == 'challenge' 
      && this.props.gameSession.placesList
      && place.id == this.props.gameSession.placesList[this.props.gameSession.currentPlacePos];
  }

  getStyle(place) {
    let isPoint = !(place.features);

    let style = {
      fillColor : place.color || colorScheme[place.status].primary,
      //fillColor: 'blue',
      weight: 0.5,
      opacity: 1,
      fillOpacity: 1
    };

    if (this.isCurrentlyFocused(place)) {
      style.fillColor = "#542FEB";
      style.weight = 2;
    }

    if (isPoint) { 
      style.radius = 25000;
      style.fillOpacity = 0.6;
      style.weight = 1;
    }

    return style;
  }

  getPlaceSubmission = (place) => {
    let submission = this.props.gameSession.submissions && this.props.gameSession.submissions.find(s=>s.place_code==place.id);
    if (!submission) return place;

    let player = this.props.gameSession.players && this.props.gameSession.players.find(p=>p.uid == submission.uid);
    
    let placeDup = JSON.parse(JSON.stringify(place));
    placeDup.status = "found";
    placeDup.uid = submission.uid;
    placeDup.color = player ? player.color : undefined;

    if (submission.uid == 'bot') {
      placeDup.color = colorScheme.failure.primary;
    }
    return placeDup;
  }

  render() {
    return <>
      <div  className="leaflet-wrapper">
        <Map  zoomControl={false} minZoom={2} maxZoom={8} animate={true}
          onViewportChanged={this.onViewportChanged}
          viewport={this.props.mapViewport}
          
          >
          {this.props.gamePlaces && this.props.gamePlaces.sort((a,b)=>{return (!!b.features) *1 - (!!a.features)*1; }).map(placeOrig=>{
            let place = this.getPlaceSubmission(placeOrig);

            return <React.Fragment key={place.id}>
              {place.features && <GeoJSON data={place.features} style={(feature) => { return this.getStyle(place) }}>
                {place.status == 'found' && <Tooltip>{place.eng}</Tooltip>}
                {this.isCurrentlyFocused(place) && <Tooltip sticky permanent className="tooltip-question-mark">?</Tooltip>}
                {this.props.gameStatus == 'gameLost' && <Tooltip sticky permanent>{place.eng}</Tooltip>}
              </GeoJSON>}

              {!place.features && <Circle center={{ lat: place.lat, lng: place.lon }} {...this.getStyle(place)}>
                {place.status == 'found' && <Tooltip className={"player-" + place.uid}>{place.eng}</Tooltip>}
                {this.isCurrentlyFocused(place) && <Tooltip sticky permanent className="tooltip-question-mark">?</Tooltip>}
                {this.props.gameStatus == 'gameLost' && <Tooltip sticky permanent>{place.eng}</Tooltip>}
              </Circle>}

            </React.Fragment>
          })}

          
          
        </Map>
      </div>
      <style jsx="true" global="true">{`
        .leaflet-wrapper, .leaflet-container {
          height: 100vh;
          width: 100%;

          background: #20c7ff;
        }

        .leaflet-tooltip {
          
          border: 0px solid black;
          
          padding: 1px 3px;
          border-radius: 0;
          opacity: 0.9;
        }

        .leaflet-tooltip::before {content: ""; display: none;}

        .tooltip-question-mark {
          background: none;
          border: none;
          box-shadow: none;

          font-size: 2.5em;
          color: white;
          font-weight: 900;
        }
      `}</style>
    </>
  }
}

export default GameMap;
