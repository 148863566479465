import React from "react";
import { Button } from "react-bootstrap";
import Avatar from "./Avatar";
import SelectGameMode from "./SelectGameMode";
import Slide from "react-reveal/Slide";

class MenuMultiplayer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedGameMode: "challenge",
    };
  }

  copyToClipboard = (e) => {
    this.textArea.select();
    document.execCommand("copy");
    e.target.focus();

    // @TODO: show a success message
    this.setState({ isCopied: true });
    setTimeout(() => {
      this.setState({ isCopied: false });
    }, 2000);
  };

  onClickGameStart = () => {
    this.props.startGame({
      isMultiplayer: true,
      requestGameStart: this.props.gameStatus == "pendingMultiplayerHost",
      game_mode: this.state.selectedGameMode,
      userInitiated: true,
    });
  };

  render() {
    let gameLink =
      window.location.origin + window.location.pathname + "?gid=" + this.props.gameSession.gid;

    return (
      <>
        <div className="menu-multiplayer menu text-white">
          <div className="px-5 py-3">
            <img
              src="img/logo.svg"
              style={{ minHeight: "100px" }}
              className="img-fluid logo"
            />
          </div>
          {!this.props.gameSession.gid && !this.props.gameSession.gameError && (
            <>
              <h4 className="pb-4">Connecting...</h4>
            </>
          )}

          {this.props.gameSession.gameError &&
            this.props.gameSession.gameError == "GAME_NOT_FOUND" && (
              <>
                <h4 className="pb-4">Game not found :(</h4>
                <p>The game might already ended.</p>
                <a href="?"><div className="btn btn-success mb-4 px-4">
                  New Game
                </div></a>
              </>
            )}

          {this.props.gameSession.gameError &&
            this.props.gameSession.gameError == "USER_LIMIT_EXCEEDED" && (
              <>
                <h4>User Limit Exceeded</h4>
                <a href="?" className="btn btn-success mb-4">
                  New game
                </a>
                <p>Interested in playing in a larger group?</p>
                <a
                  href="mailto:thegeoquiz@yaronshemesh.com"
                  className="btn btn-secondary mb-4 ml-2"
                >
                  Contact Us
                </a>
              </>
            )}

          {this.props.gameSession.gid && !this.props.gameSession.gameError && (
            <>
              <Slide bottom>
                <h4>1. Select Your Avatar</h4>
                <div className="avatarMenu my-3 d-flex mx-3">
                  {Avatar.getAvatars().map((i) => {
                    let existingPlayer = this.props.gameSession.players.find(
                      (u) => u.avatar == i
                    );
                    if (existingPlayer) {
                      return (
                        <Avatar
                          {...existingPlayer}
                          key={i}
                          animateEye={true}
                          className={
                            existingPlayer.uid != this.props.playerUID
                              ? "avatar-other"
                              : "avatar-me"
                          }
                        />
                      );
                    }
                    return (
                      <Avatar
                        avatar={i}
                        key={i}
                        onAvatarSelected={this.props.onAvatarSelected}
                      />
                    );
                  })}
                </div>
              </Slide>
             

              {this.props.gameStatus == "pendingMultiplayerHost" && (
                <div className="gameMode-wrapper text-center">
                   <h4>2. Choose Game Mode</h4>
                  <SelectGameMode
                    onGameModeSelected={(selectedGameMode) => {
                      this.setState({ selectedGameMode: selectedGameMode });
                    }}
                  />

                  {this.state.selectedGameMode == "default" && (
                    <p>TURTLE MODE: Enter as many countries as you can with friends.</p>
                  )}
                  {this.state.selectedGameMode == "challenge" && (
                    <p>CHEETA MODE: You have 15 seconds to name the country shown.</p>
                  )}
                </div>
              )}

{this.props.gameStatus == "pendingMultiplayerHost" && (
                <>
                  <Slide bottom>
                    <h4>3. Share your Game Link</h4>
                    <div
                      className={
                        "px-5 my-3 d-flex game-link-wrapper " +
                        (this.state.isCopied ? "copied" : "pendingCopy")
                      }
                    >
                      <div className="game-link">
                        <input
                          onClick={this.copyToClipboard}
                          ref={(textarea) => (this.textArea = textarea)}
                          type="text"
                          className="p-2 rounded-sm border-0 w-100 d-block"
                          defaultValue={gameLink}
                          readOnly={true}
                        />
                        <a
                          className="copy-link rounded-sm"
                          onClick={this.copyToClipboard}
                        >
                          {this.state.isCopied ? "Copied!" : "Copy"}
                        </a>
                      </div>
                      <a
                        href={
                          "https://api.whatsapp.com/send?text=" +
                          encodeURIComponent(gameLink)
                        }
                      >
                        <img src="img/btn-wa.png" className="mx-2" />
                      </a>
                    </div>
                  </Slide>
                </>
              )}

              <div className="mx-5 pb-3">
                {this.props.gameStatus == "pendingMultiplayer" && (
                  <Button
                    variant="secondary"
                    disabled={true}
                    className="w-100 my-2"
                  >
                    Waiting for host
                  </Button>
                )}
                {this.props.gameStatus == "pendingMultiplayerHost" && (
                  <Button
                    variant="success"
                    className="w-100 my-2"
                    onClick={this.onClickGameStart}
                  >
                    Start Game
                  </Button>
                )}
              </div>
            </>
          )}
        </div>
        <style jsx="true">{`
          .menu-multiplayer {
            background: #542feb;
            border-radius: 30px;

            max-width: 100%;
            width: 500px;
            margin: 0 auto;

            z-index: 9999;
          }

          .menu-multiplayer .logo {
            max-width: 250px;
          }

          .game-link {
            width: 87%;
            position: relative;
          }

          .game-link-wrapper img {
            height: 32px;
          }

          .game-link input {
            outline: 0;
          }

          .game-link-wrapper.copied .game-link {
            outline: 2px solid green;
          }

          .game-link-wrapper a {
            line-height: 40px;
            cursor: pointer;
          }

          .btn {
            border-radius: 20px;
            font-weight: 600;
          }

          h4 {
            font-weight: 400;
          }

          .avatarMenu {
            flex-wrap: wrap;
            justify-content: center;
          }

          .avatarMenu .avatar {
            cursor: pointer;
            margin: 4px;
            padding: 4px;
          }

          .avatarMenu .avatar img {
            height: 60px; 
          }

          .avatarMenu .avatar-me {
            outline: 3px solid #ff005b;
          }

          label span {
            vertical-align: bottom;
            display: inline-block;

            font-size: 1.8em;
          }

          .gameMode-wrapper {
            max-width: 300px;
            margin: 2em auto;
          }

          a.copy-link {
            position: absolute;
            top: 0px;
            height: 40px;
            right: 0px;
            background: white;
            color: green !important;
            padding: 0 10px;

            font-weight: 500;
          }
        `}</style>
      </>
    );
  }
}

export default MenuMultiplayer;
