import React from "react";
import { Button } from "react-bootstrap";

import Lottie from "react-lottie";
import * as coffeeHeart from "./../assets/heart.json";

class MenuCredit extends React.Component {
  render() {
    return (
      <>
        <div className="credit-menu-wrapper menu px-4">
          <div className="credit-menu py-5 px-2">
            <div className="btn-close" onClick={this.props.onCreditClose}></div>

            <h1 className="px-5 py-3">
              <img src="img/about.svg" alt="About" width="200px" />
            </h1>

            <div className="px-5 py-3">
               <img src='img/globe_ilustration.svg' height="250px" />
            </div>

            <p className="text-center mx-4 my-2">
              The Geo-quiz  project was created out of a love for geography and a desire to create an interactive +  playful experience through which it is possible to deepen knowledge about the world. Share the game and play with family, friends and classmates
            </p>

            <a
              href="https://www.paypal.com/donate?hosted_button_id=RNTTQW8XYWTJU"
              target="_blank"
              className="donate-link"
            >
              <Button variant="success" className="my-4 btn-donate d-flex">
                Buy us coffee
                <div className="coffeeMug">
                  <div className="coffeMugHeart">
                    <Lottie
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: coffeeHeart.default,
                        rendererSettings: {
                          preserveAspectRatio: "xMidYMid meet",
                        },
                      }}
                      height={50}
                      width={25}
                    />
                  </div>
                </div>
              </Button>
            </a>

            <p>
              <b>
                We are open for collabaration with Educational Organizations.
              </b>{" "}
              Contact us at:{" "}
              <a href="mailto:hey@thegeoquiz.com" className="text-white">
                hey@thegeoquiz.com
              </a>
            </p>
          </div>
        </div>
        <style jsx="true" global="true">{`
          .coffeeMug {
            background: url("img/cup.svg") no-repeat center center;
            width: 50px;
            height: 50px;

            margin-left: 10px;
          }
          .coffeMugHeart {
            margin-left: -10px;
            margin-top: -2px;
          }

          .btn-donate.btn-success:not(:disabled):not(.disabled).active,
          .btn-donate.btn-success:not(:disabled):not(.disabled):active,
          .btn.btn-success.btn-donate {
            background: #ff4c53;
            font-size: 2em;

            padding: 0 20px;

            justify-content: space-between;
            margin: 0 auto;
            transition: 0.6s;
            outline: 0;
          }

          .donate-link:hover {
            text-decoration: none;
          }

          .btn.btn-success.btn-donate:hover,
          .btn.btn-success.btn-donate:focus {
            box-shadow: none;
            border: 2px solid #000;
          }

          .credit-menu {
            background: #542feb;
            color: #fff;
            border-radius: 20px;

            position: relative;

            max-width: 450px;
            margin: 0 auto;
          }
          .credit-menu-wrapper {
            background: #20c7ff;
            padding-top: 60px;
            padding-bottom: 60px;
            min-height: 100vh;
          }

          .btn-close {
            left: 0;
            right: 0;
            margin: 0 auto;
            top: -15px;
          }
        `}</style>
      </>
    );
  }
}

export default MenuCredit;
